import * as yup from "yup";
import moment, { Moment } from 'moment';

import TripType from "../models/TripType";
import Luggage from "../models/Luggage";
import { BICYCLE_IDS, BICYCLE_BAGS_ID } from './slice';

const DATE_FORMAT = "YYYY-MM-DD";

const validationSchema = (restrictedDates: Moment[]) => yup.object({
  tripType: yup
    .string()
    .oneOf([TripType.ONE_WAY, TripType.RETURN]),
  departureDate: yup
    .date().typeError("invalidDate")
    .required("required")
    .test('dateNotAllowed', 'dateNotAllowed', (value: Date) => {
      if (!value) return false;
      const enteredDate = moment(value).format(DATE_FORMAT);
      
      return !restrictedDates.some(date => date.format(DATE_FORMAT) === enteredDate);
    })
    .when("tripType", {
      is: (value: TripType) => value === TripType.RETURN,
      then: (schema: yup.DateSchema<Date>) => {
        return schema.test("is-less", "departureAfterReturn", (value: Date, context: yup.TestContext) => {
          const { returnDate } = context.parent;
          if (returnDate && value) {
            const start = new Date(value);
            const end = new Date(returnDate);
            end.setDate(end.getDate() - 1);
            return start <= end;
          }
          return true;
        })
      }
    }),
  returnDate: yup
    .date().typeError("invalidDate")
    .test('dateNotAllowed', 'dateNotAllowed', (value: Date) => {
      if (!value) return true;
      const enteredDate = moment(value).format(DATE_FORMAT);
      
      return !restrictedDates.some(date => date.format(DATE_FORMAT) === enteredDate);
    })
    .when("tripType", {
      is: (value: TripType) => value === TripType.RETURN,
      then: (schema: yup.DateSchema<Date>) => {
        return schema.required("required")
          .test("is-greater", "returnBeforeDeparture", (value: Date, context: yup.TestContext) => {
            const { departureDate } = context.parent;
            if (departureDate && value) {
              const start = new Date(departureDate);
              const end = new Date(value);
              start.setDate(start.getDate() + 1);
              return end >= start;
            }
            return true;
          })
      },
      otherwise: (schema: yup.DateSchema<Date>) => schema.notRequired()
    }),
  lugagges: yup
    .array<Luggage>()
    .of(yup.object({
      typeId: yup.number().notRequired(),
      quantity: yup.number().min(1).max(50).notRequired()
    }))
    .min(1)
    .test("atLeastOneQuantitySpecified", "atLeastOneQuantityRequired", (values) => {
      return values.some(({ quantity }) => quantity !== null && quantity !== undefined);
    })
    .test("validateBicycleBags", (values) => {
      const hasBicycle = values.some(item => BICYCLE_IDS.includes(item.typeId) && Number(item.quantity) > 0);
      const bicycleBagIndex = values.findIndex(item => item.typeId === BICYCLE_BAGS_ID && Number(item.quantity) > 0);

      if (bicycleBagIndex !== -1 && !hasBicycle) {
        return new yup.ValidationError('bikeBagRequiresBicycle', null, `lugagges[${bicycleBagIndex}].quantity`);
      }

      return true;
    })
});

export default validationSchema;
